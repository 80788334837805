import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import { formatDate } from '../../../FormatDate';
import Apis from '../../../../Apis';
import { MessageByName } from './AddNotification';

const AddQuiz = ({ openQuizModal, QuizModalHandler, selectedCourse, selectedClass }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const AllQuizzes = useSelector(state => state.quizDetails);
    const userInfo = useSelector(state => state.userInfo);
    const backendBaseUrl = config.backendLocalhost;
    const ResponseMessages = useSelector(state => state.responseMessage);

    useEffect(() => {
        const filtered = AllQuizzes.filter((item) => {
            return item.course === selectedCourse && item.class === selectedClass;
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            quizNo: filtered.length + 1,
        }));
    }, [AllQuizzes, selectedCourse, selectedClass]);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    };
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
    });
    const Categories = ['Online', 'Paper-based', 'Verbal'];

    const [formData, setFormData] = useState({
        currentdate: formattedDate,
        category: 'Online',
        uploadDate: '',
        dueDate: '',
        totalMarks: '',
        weightage: '',
        facultyFile: null,
        description: '',
        quizNo: 1,
        quizDetail: [],
    });

    const resetForm = () => {
        setFormData({
            currentdate: formattedDate,
            category: 'Online',
            uploadDate: '',
            dueDate: '',
            totalMarks: '',
            weightage: '',
            facultyFile: null,
            description: '',
            quizNo: 1,
            quizDetail: [],
        });
        QuizModalHandler('', '')
    };

    const handleInputChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            facultyFile: file,
        }));
    };

    const addQuiz = async () => {
        let loadingToast = "";
        try {
            if (formData.uploadDate === '' || formData.dueDate === '') {
                if (formData.uploadDate === '') {
                    toast.error("ERROR! Select Assigning Date");
                    return;
                } else if (formData.dueDate === '') {
                    toast.error("ERROR! Select Due Date");
                    return;
                } else {
                    toast.error("ERROR! Select Assigning Date and Due Date");
                    return;
                }
            }
            const uploadDate = new Date(formData.uploadDate);
            const currentDate = new Date();
            if (uploadDate > currentDate) {
                toast.error("Assigning Date cannot exceed current date");
                return;
            }
            const dueDate = new Date(formData.dueDate);
            if (uploadDate > dueDate) {
                toast.error("Due Date cannot be less than Assigning Date");
                return;
            }
            if (formData.totalMarks === '') {
                toast.error("ERROR! Enter Total Marks");
                return;
            }
            if (formData.totalMarks <= 0) {
                toast.error("ERROR! Enter Total Marks must be greater than 0");
                return;
            }
            if (formData.facultyFile === null && formData.description === '') {
                toast.error("ERROR! Upload File or Write some description");
                return;
            }
            if (formData.category !== 'Online' && formData.category !== 'Paper-based' && formData.category !== 'Verbal') {
                toast.error("ERROR! Select Quiz Type: ", formData.category);
                return;
            }
            if (parseInt(formData.weightage) < -1 || formData.weightage === "") {
                toast.error("ERROR! Weighted Marks must be greater than -1");
                return;
            }
            const formDataToSend = new FormData();
            formDataToSend.append("currentdate", formData.currentdate);
            formDataToSend.append("uploadDate", formatDate(formData.uploadDate));
            formDataToSend.append("dueDate", formatDate(formData.dueDate));
            formDataToSend.append("category", formData.category);
            formDataToSend.append("quizNo", formData.quizNo);
            formDataToSend.append("className", selectedClass);
            formDataToSend.append("course", selectedCourse);
            formDataToSend.append("description", formData.description);
            formDataToSend.append("facultyFile", formData.facultyFile);
            formDataToSend.append("totalMarks", formData.totalMarks);
            formDataToSend.append("weightage", formData.weightage);
            formDataToSend.append("quizDetail", JSON.stringify(formData.quizDetail));
            loadingToast = toast.loading('Loading...');
            const response = await axios.post(`${backendBaseUrl}/quiz/upload-files`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const ResponseMsg = MessageByName("Quiz forwarded by the instructor", ResponseMessages);
            toast.success(ResponseMsg);
            Apis.addAuditTrail(userInfo._id, "663492334fba113615476c79", `- [ClassID:${selectedClass}, CourseID:${selectedCourse}, No:${formData.quizNo},Type:${formData.category}]`);
            toast.dismiss(loadingToast);
            resetForm();
        } catch (error) {
            console.error('Error sending data to the backend:', error);
            toast.error('Error submitting quiz:', error.message || 'Unknown error');
            toast.dismiss(loadingToast);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addQuiz();
    };

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
        ${openQuizModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">Upload Quiz</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => QuizModalHandler('', '')}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='w-[90%] h-[90%] bg-gray-100 rounded-[1vw] flex flex-col gap-y-[1vh]'>
                    <div className='flex flex-row h-[25%] justify-center items-center px-[3vw] py-[1vh] gap-x-[1vw]'>
                        {/* Text Input */}
                        <div className='flex flex-col items-start w-1/5 h-full'>
                            <p className='text-[1vw]'>Quiz No.</p>
                            <input
                                type="text"
                                id="quizNoInput"
                                className="w-full h-full text-[1vw] p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                placeholder="Quiz number"
                                value={formData.quizNo}
                                disabled
                            />
                        </div>

                        {/* Date Input */}
                        <div className='flex flex-col items-start w-1/5 h-full'>
                            <p className='text-[1vw]'>Assigning Date</p>
                            <input
                                type="date"
                                id="assigningDateInput"
                                className="w-full h-full text-[1vw] text-black text-center border border-blue-300 rounded-[1vw] focus:border-blue-900 focus:outline-none"
                                placeholder="Today's Date"
                                value={formData.uploadDate}
                                onChange={(e) => handleInputChange('uploadDate', e.target.value)}
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </div>

                        {/* Due Date Input */}
                        <div className='flex flex-col items-start w-1/5 h-full'>
                            <p className='text-[1vw]'>Due Date</p>
                            <input
                                type="date"
                                id="dueDateInput"
                                className="w-full h-full text-[1vw] text-black text-center border border-blue-300 rounded-[1vw] focus:border-blue-900 focus:outline-none"
                                placeholder="Due Date"
                                value={formData.dueDate}
                                onChange={(e) => handleInputChange('dueDate', e.target.value)}
                                min={formData.uploadDate && new Date().toISOString().split('T')[0]}
                                disabled={formData.uploadDate ? false : true}
                            />
                        </div>

                        {/* Type Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Quiz Type</p>
                            {/* Select category */}
                            <select
                                id="category"
                                className=" w-full h-full text-[1vw] p-[0.5vw] text-left bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-purple-900 transition-all duration-300"
                                value={formData.category}
                                onChange={(e) => handleInputChange('category', e.target.value)}
                            >
                                <option value="" disabled>
                                    Select Type
                                </option>
                                {Categories.map((category) => (
                                    <option key={category} value={category}>
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Total Marks Input */}
                        <div className='flex flex-col items-start w-1/5 h-full'>
                            <p className='text-[1vw]'>Total Marks</p>
                            <input
                                type="text"
                                id="totalMarksInput"
                                className="w-full h-full text-[1vw] p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                placeholder="Quiz's Marks"
                                value={formData.totalMarks}
                                onChange={(e) => handleInputChange('totalMarks', e.target.value)}
                            />
                        </div>

                        {/* weightage Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Weightage Marks</p>
                            <input
                                type="text"
                                id="weightage"
                                className="w-full h-full text-[1vw] p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                placeholder="Weightage"
                                value={formData.weightage}
                                onChange={(e) => handleInputChange('weightage', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col h-[35%] justify-center items-center'>
                        <div className='w-[90%] h-[20%] flex items-end'>
                            <p className='text-[1.2vw] text-black text-left'>Brief Description:</p>
                        </div>
                        <textarea
                            className='h-[80%] w-[90%] text-[1.2vw] p-[1vw] style-scrollbar border border-blue-300 focus:border-blue-900 outline-blue-900 duration-500'
                            placeholder='Write the description here...'
                            value={formData.description}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                        />
                    </div>

                    <div className='flex flex-col w-full h-[15%] justify-center items-center'>
                        <input
                            id="file_input"
                            type="file"
                            className="block w-[90%] px-[1vw] py-[1vh] leading-[3vh] transition-all duration-300 ease-in-out border border-blue-300 rounded-md appearance-none focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            onChange={(e) => handleFileChange(e)}
                        />
                    </div>

                    <div className='flex flex-row h-[15%] justify-center items-center py-[1vh] gap-x-[1vw]'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}
                            className='h-full w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-green-400 hover:text-white hover:bg-green-700 duration-300'>
                            Upload
                        </button>
                        <button
                            onClick={() => QuizModalHandler('', '')}
                            className='h-full w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-red-400 hover:text-white hover:bg-red-700 duration-300'>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddQuiz;
