import { useState } from "react";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Header from "../Header/Header";
import Footer from "../Header/Footer";
import config from "../../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Signup = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    cnic: '',
    email: '',
    password: '',
    role: "student"
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const backendBaseUrl = config.backendLocalhost;

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const resetField = () => {
    setData({
      firstName: '',
      lastName: '',
      phoneNo: '',
      cnic: '',
      email: '',
      password: '',
      role: "student"
    })
    navigate('/')
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cnicRegex = /^\d{13}$/;
    if (!cnicRegex.test(data.cnic)) {
      toast.error('Invalid CNIC format. Please enter a valid CNIC number.');
      return;
    }
    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(data.phoneNo.replace(/-/g, ''))) {
      toast.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
      return;
    }

    try {
      const response = await axios.post(`${backendBaseUrl}/api/users/signup`, data);
      toast.success(response.data.message);
      resetField();
    } catch (error) {
      toast.error("Error! While creating Account");
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="w-full h-full">
      <Header />
      <div className={styles.signup_container}>
        <div className={styles.signup_form_container}>
          <div className={styles.left}>
            <h1>Welcome Back</h1>
            <Link to="/">
              <button type="button" className={styles.white_btn}>
                Sing in
              </button>
            </Link>
          </div>
          <div className={styles.right}>
            <form className={styles.form_container} onSubmit={handleSubmit}>
              <h1>Create Account</h1>
              <div className="grid items-center justify-between w-full grid-cols-1 grid-rows-1 gap-x-4 lg:grid-cols-2 ">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      firstName: e.target.value
                    }))
                  }
                  value={data.firstName}
                  required
                  className={styles.input}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      lastName: e.target.value
                    }))
                  }
                  value={data.lastName}
                  required
                  className={styles.input}
                />
              </div>
              <div className="grid items-center justify-between w-full grid-cols-1 grid-rows-1 gap-x-4 lg:grid-cols-2">
                <input
                  type="text"
                  placeholder="Enter Phone"
                  name="phoneNo"
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      phoneNo: e.target.value
                    }))
                  }
                  value={data.phoneNo}
                  required
                  className={styles.input}
                />
                <input
                  type="text"
                  placeholder="Enter CNIC"
                  name="cnic"
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      cnic: e.target.value
                    }))
                  }
                  value={data.cnic}
                  required
                  className={styles.input}
                />
              </div>
              <div className="grid items-center justify-between w-full grid-cols-1 grid-rows-1 gap-x-4 lg:grid-cols-2">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      email: e.target.value
                    }))
                  }
                  value={data.email}
                  required
                  className={styles.input}
                />
                <div className="relative w-full">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    onChange={(e) =>
                      setData((prevVal) => ({
                        ...prevVal,
                        password: e.target.value
                      }))
                    }
                    value={data.password}
                    required
                    className={styles.input}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                    onClick={handleTogglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <div className="grid items-center justify-between w-full grid-cols-1 grid-rows-1 gap-x-4 lg:grid-cols-2">
                <select
                  name="role"
                  value={data.role}
                  onChange={(e) =>
                    setData((prevVal) => ({
                      ...prevVal,
                      role: e.target.value
                    }))
                  }
                  className="w-full p-[1vw] bg-[#edf5f3] rounded-[1vw]"
                >
                  <option value="student">Student</option>
                  <option value="faculty">Faculty</option>
                </select>

                <button type="submit" className={styles.green_btn}>
                  Sing Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;