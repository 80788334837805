import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import { config } from '@fortawesome/fontawesome-svg-core';

const ViewAssignment = ({ AssignmentModalHandler, AssignmentDetailHandler }) => {

  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const backendBaseUrl = config.backendLocalhost;
  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };
  useEffect(() => {
    const filteredCourses = userInfo.facultyDetails.courseDetail
      .filter(info => info.class === selectedClass)
      .map(info => info.course);
    setCourses(filteredCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
    setClasses(uniqueClasses);
  }, []);


  const assignments = useSelector(state => state.assignmentDetails);

  useEffect(() => {
    const sortedAssignments = [...assignments].sort((b, a) => new Date(a.uploadDate) - new Date(b.uploadDate));
    if (sortedAssignments.length !== 0) {
      let filtered = sortedAssignments.filter((assignment) => {
        if (!selectedCourse && !selectedClass) {
          return true;
        }
        if (selectedCourse && selectedClass) {
          return (
            assignment.course === selectedCourse &&
            assignment.class === selectedClass
          );
        }
        if (selectedCourse) {
          return assignment.course === selectedCourse;

        }
        if (selectedClass) {
          return assignment.class === selectedClass;

        }
        return false;
      });
      if (selectedCourse && selectedClass) {
        setFilteredAssignments(filtered);
      }
      else {
        setFilteredAssignments([]);
      }
    }
  }, [selectedCourse, selectedClass, assignments]);

  function countStatus(assignDetail, countVariable) {
    let count = 0;

    for (const assignment of assignDetail) {
      if (assignment.status === countVariable) {
        count++;
      }
      else if (countVariable === "submitted") {
        count++;
      }
    }
    return count;
  }

  return (
    <div className={`${maxScreen ? "fixed w-full h-full top-0 left-0 z-20" : "w-full text-white h-[5vh] rounded-[1vw] h-auto"} bg-slate-200`}>
      <div className='w-full pb-[2vh] px-[1vw]'>
        <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh]'>
          {/* Select Class */}
          <select
            id="classSelect"
            className='w-auto h-full text-black text-left border border-red-700 rounded-[1vw] px-[1vw] focus:border-red-700 focus:outline-none cursor-pointer'
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
          >
            <option value="">Select a class</option>
            {classes.map((className) => (
              <option key={className} value={className}>
                {className}
              </option>
            ))}
          </select>

          {/* Select Course */}
          <select
            id="courseSelect"
            className='w-auto h-full text-black text-left border border-red-700 rounded-[1vw] px-[1vw] focus:border-red-700 focus:outline-none cursor-pointer'
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course} value={course}>
                {course}
              </option>
            ))}
          </select>
          {/* Add Assignment */}
          <button className='w-auto h-full text-[1.2vw] px-[1vw] text-center border border-red-700 rounded-[1vw] focus:border-red-700 focus:outline-none
          bg-white text-black hover:bg-red-700 hover:text-white duration-300'
            onClick={() => AssignmentModalHandler(selectedCourse, selectedClass)}>
            Upload Assignment
          </button>
        </div>
        <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto'>
          <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No</th>
                <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Upload Date</th>
                <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Due Date</th>
                <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Total Marks</th>
                <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Weightage</th>
                <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Submitted</th>
                <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Marked</th>
                <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">File</th>
                <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">View Detail</th>
              </tr>
            </thead>
            <tbody>
              {filteredAssignments && filteredAssignments.map((row, index) => (
                <tr key={row.sr} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}`}
                >
                  <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.assignmentNo}</td>
                  <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.uploadDate}</td>
                  <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.dueDate}</td>
                  <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.totalMarks}</td>
                  <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.weightage}</td>
                  <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.assignDetail, "submitted")}</td>
                  <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.assignDetail, "marked")}</td>
                  <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                    <a
                      className={`text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer
                ${row.facultyFile === "no file" ? "hidden" : ""}
              `}
                      href={`${backendBaseUrl}/files/${row.facultyFile}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Attachment
                    </a>
                  </td>
                  <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                    <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                      onClick={() => AssignmentDetailHandler(row)}
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      : <></>
    </div>
  )
}

export default ViewAssignment