import axios from 'axios';
import config from './config';
import { toast } from "react-hot-toast";

const { backendLocalhost } = config;

const handleError = (error) => {
  toast.error('Error:', error.response ? error.response.data : error.message);
  console.error('Error:', error.response ? error.response.data : error.message);
};

const getUserInfo = async () => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('Token is missing');
      return;
    }
    const headers = {
      Authorization: token,
    };
    const response = await axios.get(`${backendLocalhost}/userInfo/getUserInfo`, { headers });
    return response.data.userInfo;
  } catch (error) {
    handleError(error);
  }
};
const getConfigureName = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/configuration/getName`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
const getAllAssignments = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/assignment/getAllAssignment`);
    return response.data.data;
  } catch (error) {
    handleError(error);
  }
};
const getAllQuizzes = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/quiz/getAllQuizzes`);
    return response.data.data;
  } catch (error) {
    handleError(error);
  }
};
const fetchLearnings = async () => {
  try {
    const response = await fetch(`${backendLocalhost}/learning/getAllLearning`);
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};
const getLearningByClass = async (classCode) => {
  try {
    const response = await fetch(`${backendLocalhost}/learning/getLearningByClass?classCode=${encodeURIComponent(classCode)}`);
    const data = await response.json();
    return data;
  } catch (error) {
    handleError(error);
  }
};
const getAllAttendance = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/attendance/getAllAttendance`); // Assuming '/assignments' is the endpoint to get all assignments
    return response.data.data
  } catch (error) {
    handleError(error);
  }
};
const updateUserProfileByID = async (id, formData) => {
  try {
    const response = await axios.put(`${backendLocalhost}/api/users/update/${id}`, formData);
    toast.success(response.data.message);
  } catch (error) {
    return error;
  }
};
const getAllExam = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/exam/getexam`);
    return response.data.data
  } catch (error) {
    handleError(error);
  }
};
const getAllDepartment = async (searchDepartment,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/department/getAllDepartments`, {
      params: {
        search: searchDepartment,
        user:user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllCourses = async (searchCourses,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/courses/getAllCourses`, {
      params: {
        search: searchCourses,
        user:user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getCoursesByClass = async (classCode) => {
  try {
    const response = await axios.get(`${backendLocalhost}/courses/getCoursesByClass`, {
      params: {
        classCode: classCode,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllProgram = async (searchProgram,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/program/getAllProgram`, {
      params: {
        search: searchProgram,
        user:user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllClasses = async (searchClasses,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/class/getAllClass`, {
      params: {
        search: searchClasses,
        user: user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllSession = async (searchSession,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/session/getAllSessions`, {
      params: {
        search: searchSession,
        user: user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getActiveSessions = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/session/getActiveSessions`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllStudent = async (searchStudent,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getAllStudent`, {
      params: {
        search: searchStudent,
        user: user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getActiveStudents = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getActiveStudents`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllExcutiveAdmin = async (searchStudent) => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getExcutiveAdmin`, {
      params: {
        search: searchStudent
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllExcutiveManagement = async (searchStudent,userID) => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getAllExcutiveManagement`, {
      params: {
        search: searchStudent,
        userID:userID,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllFaculty = async (searchFaculty,user) => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getAllFaculty`, {
      params: {
        search: searchFaculty,
        user: user,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getActiveFaculties = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/userInfo/getActiveFaculties`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllActiveSession = async (userID) => {
  try {
    const response = await axios.get(`${backendLocalhost}/session/getActiveSessions`, {
      params: {
        userID:userID,
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllWeeklySchedule = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/schedules`, {
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllAcademic = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/academic/getAcademic`, {
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllCoursePlan = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/courseplan/getcourseplan`, {
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getCoursePlan = async (className, course) => {
  try {
    const response = await axios.get(`${backendLocalhost}/courseplan/getbyCourseClass`, {
      params: {
        course: course,
        class: className
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const Logout = async (userID) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('Token is missing');
      return;
    }
    const headers = {
      Authorization: token,
    };
    const response = await axios.get(`${backendLocalhost}/api/users/logout/${userID}`, { headers });
    return response.data.message;
  } catch (error) {
    handleError(error);
  }
};
const addAuditTrail = async (userID, messageID, extendedmessage) => {
  try {
    const response = await axios.post(`${backendLocalhost}/auditTrail/audit-trail`, {
      userID,
      messageID,
      extendedmessage,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
const getAuditTrailByUserID = async (id) => {
  try {
    const response = await axios.get(`${backendLocalhost}/auditTrail/getByID/${id}`, {
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};
const getAllAuditTrail = async (searchDepartment) => {
  try {
    const response = await axios.get(`${backendLocalhost}/auditTrail/getAll`, {
      params: {
        search: searchDepartment
      }
    });
    return response.data
  } catch (error) {
    handleError(error);
  }
};

const ThemeChanged = async (UserID) => {
  try {
    const response = await axios.put(`${backendLocalhost}/userInfo/updateUserTheme/${UserID}`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};

const fetchAllRepsonseMessages = async (searchId) => {
  try {
    const response = await axios.get(`${backendLocalhost}/configuration/getResponseMsg/${searchId}`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};

const getDetailPerformanceReport = async (classCode, courseCode) => {
  try {
    const response = await axios.get(`${backendLocalhost}/Reports/classes/${classCode}/courses/${courseCode}`);
    return response.data
  } catch (error) {
    handleError(error);
  }
};

const getStudentDashboardDetail = async (studID) => {
  try {
    const response = await axios.get(`${backendLocalhost}/Reports/studDashboard/${studID}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getNotificatioByStudID = async (studID, className) => {
  try {
    const response = await axios.get(`${backendLocalhost}/notification/getNotification/${studID}/${className}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const MarkCompletedOnlineClassByFaculty = async (id) => {
  try {
    const response = await axios.put(`${backendLocalhost}/onlineLecture/markcompleted/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getAcademicCalenderBySessionCode = async (sessionCode) => {
  try {
    const response = await axios.get(`${backendLocalhost}/academic/getBySession/${sessionCode}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getFacultyByClassandCourse = async (classCode, courseCode) => {
  try {
    const response = await axios.get(`${backendLocalhost}/survey/faculty?classCode=${classCode}&courseCode=${courseCode}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getAllSelfLearning = async (classCode, courseCode) => {
  try {
    const response = await axios.get(`${backendLocalhost}/selfLearning/getAll`, {
      params: { classCode, courseCode }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getSelfLearningByID = async (Id) => {
  try {
    const response = await axios.get(`${backendLocalhost}/selfLearning/getByID`, {
      params: { Id }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getAllAssignedSurveysNameOnly = async () => {
  try {
    const response = await axios.get(`${backendLocalhost}/survey/getAllAssignedSurveysNameOnly`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getStudentsInfoByAssignedSurveyID = async (id) => {
  try {
    const response = await axios.get(`${backendLocalhost}/survey/getStudentsInfoByAssignedSurveyID/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserInfo,
  getConfigureName,
  getAllAssignments,
  getAllQuizzes,
  fetchLearnings,
  getLearningByClass,
  getAllAttendance,
  updateUserProfileByID,
  getAllExam,
  getAllDepartment,
  getAllCourses,
  getCoursesByClass,
  getAllProgram,
  getAllClasses,
  getAllSession,
  getActiveSessions,
  getAllStudent,
  getActiveStudents,
  getAllFaculty,
  getActiveFaculties,
  getAllActiveSession,
  getAllWeeklySchedule,
  getAllAcademic,
  Logout,
  getAllCoursePlan,
  addAuditTrail,
  getAuditTrailByUserID,
  getAllAuditTrail,
  getCoursePlan,
  ThemeChanged,
  getDetailPerformanceReport,
  fetchAllRepsonseMessages,
  getStudentDashboardDetail,
  getNotificatioByStudID,
  MarkCompletedOnlineClassByFaculty,
  getAcademicCalenderBySessionCode,
  getAllExcutiveAdmin,
  getAllExcutiveManagement,
  getFacultyByClassandCourse,
  getAllSelfLearning,
  getSelfLearningByID,
  getAllAssignedSurveysNameOnly,
  getStudentsInfoByAssignedSurveyID,
};