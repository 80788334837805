import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import { toast } from "react-hot-toast";
import axios from 'axios';
import config from '../../../../config';
import {MessageByName} from "../../../../components/FacultyComp/Dashboard/Modals/AddNotification"
import { formatDate } from '../../../../components/FormatDate';
import Apis from '../../../../Apis';


const ViewAttendance = () => {
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [formatedDate, setFormatedDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState(getCurrentTime());
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const allStudentInfo = useSelector(state => state.allStudentInfo);
  const ResponseMessages = useSelector(state => state.responseMessage);

  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };
  // Dummy data for options
  const periods = [
    { periodNo: '1', periodTime: '08:40 - 09:30' },
    { periodNo: '2', periodTime: '09:40 - 10:30' },
    { periodNo: '3', periodTime: '10:40 - 11:30' },
    { periodNo: '4', periodTime: '11:40 - 12:30' },
    { periodNo: '5', periodTime: '12:40 - 13:30' },
    { periodNo: '6', periodTime: '13:40 - 14:30' },
    { periodNo: '7', periodTime: '14:40 - 15:30' },
    { periodNo: '8', periodTime: '15:40 - 16:30' },
    { periodNo: '9', periodTime: '16:40 - 17:30' },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredData = selectedDate === '' ? [] : allStudentInfo
    .filter((row) =>
      (selectedClass === '' || row.studentDetails?.class === selectedClass)
    )
    .map(row => ({
      ...row,
      attendance: "Present"
    }));

  const [attendanceData, setAttendanceData] = useState(filteredData);

  const backendBaseUrl = config.backendLocalhost;


  const handleAttendanceChange = (index, newValue) => {
    const newData = [...attendanceData];
    newData[index].attendance = newValue;
    setAttendanceData(newData);
  };

  function getCurrentTime() {
    const currentDate = new Date();
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    const formattedHours = currentHours < 10 ? `0${currentHours}` : `${currentHours}`;
    const formattedMinutes = currentMinutes < 10 ? `0${currentMinutes}` : `${currentMinutes}`;
    return `${formattedHours}:${formattedMinutes}`;
  }

  useEffect(() => {
    setSelectedTime(getCurrentTime());
    if (selectedCourse && selectedClass) {
      setAttendanceData(filteredData);
    }
    setFormatedDate(formatDate(selectedDate));
  }, [selectedDate, selectedClass, selectedCourse]);


  useEffect(() => {
    const filteredCourses = userInfo.facultyDetails.courseDetail
      .filter(info => info.class === selectedClass)
      .map(info => info.course);
    setCourses(filteredCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  function resetAttendance() {
    const ResponseMsg = MessageByName("Attendance Marked",ResponseMessages);
    toast.success(`${ResponseMsg}`);
    setSelectedClass();
    setSelectedCourse();
    setSelectedDate('');
    setFormatedDate('');
    setSelectedPeriod('');
    setAttendanceData([]);
  }

  const addAttendance = async (attendanceData) => {
    if (!formatedDate || formatedDate === "aN-undefined-NaN") {
      toast.error('Error! Select Date');
      return
    }
    if (!selectedPeriod || selectedPeriod === "") {
      toast.error('Error! Select Period');
      return
    }
    const loadingToast = toast.loading('Loading...');
    try {
      const apiUrl = `${backendBaseUrl}/attendance/addAttendance`;
      const response = await axios.post(apiUrl, {
        course: selectedCourse,
        classInfo: selectedClass,
        date: formatedDate,
        time: selectedTime,
        periods: selectedPeriod,
        studInfo: attendanceData.map(student => ({
          StudID: student._id,
          StudRollno: student.studentDetails.rollNo,
          StudName: student.name,
          attendance: student.attendance,
        })),
      });
      // Handle success
      Apis.addAuditTrail(userInfo._id,"663492624fba113615476c8f",`- [ClassID:${selectedClass}, CourseID:${selectedCourse}, Period:${selectedPeriod}, Date:${formatedDate}]`);
      resetAttendance()
      toast.dismiss(loadingToast);
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      if (error.message === 'Request failed with status code 400') {
        toast.error('Error! Attendance Already exist');
        toast.dismiss(loadingToast);
      } else {
        toast.error('Please Select fields');
        toast.dismiss(loadingToast);
      }
      // throw error;
    }
  };

  return (
    <div className={` ${maxScreen ? "fixed w-full h-full top-0 left-0 z-20":"w-full text-white rounded-[1vw] h-auto overflow-hidden"} bg-slate-200`}>
        <div className='w-full '>
          <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
            {/* Select Class */}
            <select
              id="classSelect"
              className='w-auto h-full text-black text-left border border-green-700 rounded-[1vw] px-[1vw] focus:border-green-700 focus:outline-none cursor-pointer'
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select a Class</option>
              {classes.map((className) => (
                <option key={className} value={className}>
                  {className}
                </option>
              ))}
            </select>

            {/* Select Course */}
            <select
              id="courseSelect"
              className='w-auto h-full text-black text-left border border-green-700 rounded-[1vw] px-[1vw] focus:border-green-700 focus:outline-none cursor-pointer'
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="">Select a Course</option>
              {courses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>

            {/* Select Date */}
            <input
              type="date"
              id="dateInput"
              className='w-auto h-full text-black text-left border border-green-700 rounded-[1vw] px-[1vw] focus:border-green-700 focus:outline-none cursor-pointer'
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              placeholder="dd-mm-yyyy"
              max={new Date().toISOString().split('T')[0]}
            />

            {/* Select Period */}
            <select
              id="periodSelect"
              className='w-auto h-full text-black text-left border border-green-700 rounded-[1vw] px-[1vw] focus:border-green-700 focus:outline-none cursor-pointer'
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              <option value="">Select a Period</option>
              {periods.map((period) => (
                <option key={period.periodNo} value={`${period.periodNo}: ${period.periodTime}`}>
                  {`${period.periodNo}: ${period.periodTime}`}
                </option>
              ))}
            </select>

          </div>
          <div className='w-full h-[80vh] overflow-auto style-scrollbar'>
            <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="px-[1vw] py-[1vh] border-b border border-gray-400">Sr</th>
                  <th className="px-[1vw] py-[1vh] border-b text-left border border-gray-400">Roll No</th>
                  <th className="px-[1vw] py-[1vh] border-b text-left border border-gray-400">Name</th>
                  <th className="px-[1vw] py-[1vh] border-b border border-gray-400">Attendance</th>
                  <th className="px-[1vw] py-[1vh] border-b border border-gray-400 text-left">Date</th>
                  <th className="px-[1vw] py-[1vh] border-b border border-gray-400 text-left">Period</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData &&   selectedCourse && selectedClass && selectedDate && selectedPeriod && attendanceData.map((row, index) => (
                  <tr key={row.sr} className={index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}>
                    <td className="px-[1vw] py-[1vh] border-b text-center">{index + 1}</td>
                    <td className="px-[1vw] py-[1vh] border-b text-left">{row.studentDetails?.rollNo}</td>
                    <td className="px-[1vw] py-[1vh] border-b text-left">{row.firstName + " " + row.lastName}</td>
                    <td className="px-[1vw] py-[1vh] border-b text-center">
                      <select
                        className="select select-primary min-h-[1vh] h-[5vh] w-full bg-white"
                        name="selectedProgram"
                        value={row.attendance}
                        onChange={(e) => handleAttendanceChange(index, e.target.value)}
                      >
                        <option className='text-black' value={"Present"}>
                          {`Present`}
                        </option>
                        <option className='text-black' value={"Late"}>
                          {`Late`}
                        </option>
                        <option className='text-black' value={"Absent"}>
                          {`Absent`}
                        </option>
                        <option className='text-black' value={"Medical Leave"}>
                          {`Medical Leave`}
                        </option>
                      </select>
                    </td>
                    <td className="px-[1vw] py-[1vh] border-b text-left">{`${selectedDate ? formatedDate : ""}`}</td>
                    <td className="px-[1vw] py-[1vh] border-b text-left">{selectedPeriod}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='w-full h-[5vh] flex justify-center'>
            <button className='flex items-center justify-center w-[20%] h-full text-[1.2vw] rounded-t-[1vw] tracking-widest font-semibold hover:text-black hover:bg-green-300 text-white bg-green-700 duration-300'
              onClick={() => addAttendance(attendanceData)}>
              Mark Attendance
            </button>
          </div>
        </div>
        : <></>
    </div>
  )
}

export default ViewAttendance
