import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import { formatDate } from '../../../FormatDate';
import { useSelector } from 'react-redux';
import Apis from '../../../../Apis';
import {MessageByName} from './AddNotification';

const AddLearningMaterialsModal = ({ openLearningMaterial, LearningMaterialHandler, selectedCourse, selectedClass}) => {
  const [maxScreen, setMaxScreen] = useState(false);
  const userInfo = useSelector(state => state.userInfo);
  const ResponseMessages = useSelector(state => state.responseMessage);
  const backendBaseUrl = config.backendLocalhost;
  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
  }
  const [formData, setFormData] = useState({
    category: '',
    currentdate: new Date().toISOString().split('T')[0],
    description: '',
    validFromDate: '',
    validtoDate: '',
    learningFile: null,
});


const resetForm = () => {
  setFormData({
    category: '',
    currentdate: new Date().toISOString().split('T')[0],
    description: '',
    validFromDate: '',
    validtoDate: '',
    learningFile: null,
  });
  LearningMaterialHandler('', '')
};

const handleInputChange = (name, value) => {
  setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
  }));
};

const handleFileChange = (e) => {
  const file = e.target.files[0];
  setFormData((prevFormData) => ({
      ...prevFormData,
      learningFile: file,
  }));
};
  
const Categories = ['E-books','Audios', 'Videos & presentations'];

  const addLearning = async () => {
    let loadingToast = "";
    try {
        // Check for required fields
        if (formData.description === '' ||  formData.category === '' || formData.validFromDate === undefined || formData.validtoDate === '' || formData.currentdate === '') {
            toast.error("ERROR! Please fill in all required fields");
            return;
        }

        // Check for either learningFile or description
        if (!formData.learningFile || formData.learningFile === null) {
            toast.error("ERROR! Upload valid learning File ");
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append("course", selectedCourse);
        formDataToSend.append("className", selectedClass);
        formDataToSend.append("category", formData.category);
        formDataToSend.append("currentdate", formatDate(formData.currentdate)); 
        formDataToSend.append("description", formData.description);
        formDataToSend.append("validFromDate", formatDate(formData.validFromDate));
        formDataToSend.append("validtoDate", formatDate(formData.validtoDate));
        formDataToSend.append("learningFile", formData.learningFile);
        loadingToast = toast.loading('Loading...');
        const response = await axios.post(`${backendBaseUrl}/learning/create`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const ResponseMsg = MessageByName("Upload Learning Material",ResponseMessages);
        toast.success(ResponseMsg);
        Apis.addAuditTrail(userInfo._id,"663491f34fba113615476c53",`- [ClassID:${selectedClass}, CourseID:${selectedCourse}, Type:${formData.category}]`);
        toast.dismiss(loadingToast);
        resetForm();
    } catch (error) {
        console.error('Error sending data to the backend:', error);
        toast.error('Error submitting learning:', error.message || 'Unknown error');
        toast.dismiss(loadingToast);
    }
};
 
  const handleSubmit = (e) => {
    e.preventDefault();
    addLearning();
};

  return (
    <div
      className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
        ${openLearningMaterial
          ? "scale-100 duration-300"
          : "scale-0 -z-10"
        }
        ${maxScreen
          ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
          : "w-[70vw] h-[70vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
        }
      `}
    >
      <header
        className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
          }`}
      >
        <div>
          <h1 className="text-[2vw] font-semibold">Upload Document</h1>  
        </div>
        <div className="flex gap-x-[1vw]">
          <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
            <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
          </button>
          <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
            {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
              <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
          </button>
          {/* Provide the implementation for AddProjectHandler */}
          <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => LearningMaterialHandler('', '')}>
            <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
          </button>
        </div>
      </header>
      <div className={`w-full flex flex-col justify-center items-center
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
        <div className='w-[90%] h-[90%] bg-gray-100 rounded-[1vw] flex flex-col gap-y-[1vh]'>
          <div className='flex flex-row h-[15%] justify-start items-center px-[3vw] py-[1vh] gap-x-[1vw]'>
            {/* Select category */}
            <div className='w-1/3 h-full'>
              <select
                id="category"
                className=" w-full h-full text-[1vw] p-[0.5vw] border border-blue-300 rounded-md bg-transparent focus:outline-none hover:cursor-pointer focus:border-purple-900 text-center transition-all duration-300"
                value={formData.category}
                onChange={(e) => handleInputChange('category', e.target.value)}
              >
                 <option value='' disabled>Select a type</option>
                {Categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>       
            {/* Date Input */}
            <input
              type="date"
              id="currentdate"
              className=" w-1/3 h-full text-[1vw] p-[0.5vw] border border-blue-300 rounded-md bg-transparent focus:outline-none hover:cursor-pointer focus:border-purple-900 text-center transition-all duration-300"
              value={formData.currentdate}
              onChange={(e) => handleInputChange('currentdate', e.target.value)}
            />
          </div>
          <div className='flex flex-col h-[40%] justify-center items-center'>
            <div className='w-[90%] h-[20%] flex items-end'>
            <p className='text-[1.2vw] text-black text-left'>Title/Description:</p>
            
            </div>
            <textarea
              id='description'
              className='h-[80%] w-[90%] text-[1.2vw] p-[1vw] style-scrollbar border border-blue-300 focus:border-blue-900 outline-blue-900 duration-500'
              placeholder='Write the description here...'
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            >
            </textarea>

          </div>
          
    <div className='flex flex-col w-full h-[15%] justify-center items-center'>
        <input
            id="file_input"
            type="file"
            className="block w-[90%] px-[1vw] py-[1vh] leading-[3vh] transition-all duration-300 ease-in-out border border-blue-300 rounded-md appearance-none focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            onChange={(e) => handleFileChange(e)}
        />
    </div>
    <div className='flex flex-col h-[20%] justify-center items-center'>
    <div className='w-[90%] h-[70%] flex justify-center gap-x-[1vw]'>
        <div className="flex w-1/2 h-full">
            <div className='w-[20%] h-full flex items-center justify-center'>
                <label className="text-[1vw] font-semibold text-gray-700 text-center">Valid From:</label>
            </div>
        <input
          id='validFromDate'
          type="date"
          value={formData.validFromDate}
          onChange={(e) => handleInputChange('validFromDate', e.target.value)}
          className="w-[80%] p-[1vw] border text-[1vw] border-blue-300 rounded"
          max={formData.validtoDate}
        />
      </div>

      <div className="flex w-1/2">
      <div className='w-[20%] h-full flex items-center justify-center'>
        <label className="text-[1vw] font-semibold text-gray-700">Valid To:</label>
       
        </div>
        <input
          id='validtoDate'
          type="date"
          value={formData.validtoDate}
          onChange={(e) => handleInputChange('validtoDate', e.target.value)}
          className="w-[80%] p-[1vw] text-[1vw] border border-blue-300 rounded"
          min={formData.validFromDate}
          disabled={formData.validFromDate ? false:true}
        />
      </div>
            </div>
          </div>
          <div className='flex flex-row h-[20%] justify-center items-center py-[1vh] gap-x-[1vw]'>
            <button
             onClick={(e) => {
              e.preventDefault();
              handleSubmit(e);
          }} className='h-full w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-green-400 hover:text-white hover:bg-green-700 duration-300'>
              Upload
            </button>
            <button 
            onClick={() => LearningMaterialHandler('', '')}
            className='h-full w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-red-400 hover:text-white hover:bg-red-700 duration-300'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default AddLearningMaterialsModal