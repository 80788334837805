import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCaretDown,faArrowUpRightFromSquare, faExpand, faCompress, } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import config from '../../../../config';

const ViewExam = ({ ExamModalHandler, ExamDetailHandler }) => {
  // State variables to manage selected values
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [filteExam, setFilteExam] = useState([]);
  const dispatch = useDispatch();
  const backendBaseUrl = config.backendLocalhost;
  const selectedActivity = useSelector((state) => state.selectedActivity);
  const userInfo = useSelector((state) => state.userInfo);
  
  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  useEffect(() => {
    const filteyellowCourses = userInfo.facultyDetails.courseDetail
      .filter((info) => info.class === selectedClass)
      .map((info) => info.course);
    setCourses(filteyellowCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map((info) => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  const AllExam = useSelector(state => state.allExam);

  useEffect(() => {
    const sortedExam = [...AllExam].sort((b, a) => new Date(a.uploadDate) - new Date(b.uploadDate));
    if (sortedExam.length !== 0) {
      let filteyellow = sortedExam.filter((exam) => {
        if (!selectedCourse && !selectedClass) {
          return true;
        }
        if (selectedCourse && selectedClass) {
          return (
            exam.course === selectedCourse &&
            exam.class === selectedClass
          );
        }
        if (selectedCourse) {
          return exam.course === selectedCourse;
        }
        if (selectedClass) {
          return exam.class === selectedClass;
        }
        return false;
      });

      if (selectedCourse && selectedClass) {
        setFilteExam(filteyellow);
      }
      else {
        setFilteExam([])
      }

    }
  }, [selectedCourse, selectedClass, AllExam]);

  function countStatus(examDetail, countVariable) {
    let count = 0;
    for (const exam of examDetail) {
      if (exam.status === countVariable) {
        count++;
      }
      else if (countVariable === "submitted") {
        count++;
      }
    }
    return count;
  }

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if(maxScreen){
      handleActivityList("Exam")
    }
    else{
      handleActivityList("")
    }
};

  return (
    <div className='w-full pb-[2vh] px-[1vw]'>
    <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
      {/* Select Class */}
      <select
        id='classSelect'
        className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
        value={selectedClass}
        onChange={(e) => setSelectedClass(e.target.value)}
      >
        <option value=''>Select a Class</option>
        {classes.map((className) => (
          <option key={className} value={className}>
            {className}
          </option>
        ))}
      </select>
      {/* Select Course */}
      <select
        id='courseSelect'
        className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
        value={selectedCourse}
        onChange={(e) => setSelectedCourse(e.target.value)}
      >
        <option value=''>Select a Course</option>
        {courses.map((course) => (
          <option key={course} value={course}>
            {course}
          </option>
        ))}
      </select>
      {/* Add Exam */}
      <button
        className='w-auto px-[1vw] h-full text-[1.2vw] text-center border border-yellow-700 rounded-[1vw] focus:border-yellow-700 focus:outline-none
    bg-white text-black hover:bg-yellow-700 hover:text-white duration-300'
        onClick={() => ExamModalHandler(selectedCourse, selectedClass)}
      >
        Upload Exam
      </button>
    </div>
    <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto'>
      <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
        <thead className="sticky top-0 bg-white">
          <tr>
            <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No</th>
            <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Exam Type</th>
            <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Upload Date</th>
            <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Total Marks</th>
            <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Weightage</th>
            <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Submitted</th>
            <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Marked</th>
            <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">File</th>
            <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">View Detail</th>
          </tr>
        </thead>
        <tbody>
          {filteExam && filteExam.map((row, index) => (
            <tr key={row.sr} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}`}
            >
              <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.examNo}</td>
              <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.category}</td>
              <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.uploadDate}</td>
              <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.totalMarks}</td>
              <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.weightage}</td>
              <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.examDetail, "submitted")}</td>
              <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.examDetail, "marked")}</td>
              <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                <a
                  className={`text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer
          ${row.examFile === "no file" ? "hidden" : ""}
        `}
                  href={`${backendBaseUrl}/files/${row.examFile}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Attachment
                </a>
              </td>
              <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                  onClick={() => ExamDetailHandler(row)}
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default ViewExam;