import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import { useSelector } from 'react-redux';
import Apis from '../../../../Apis';

export const MessageByName = (name, res) => {
  if (!res) {
    return name;
  }
  const messageObject = res.find(obj => obj.Name === name);
  return messageObject ? messageObject.Message : name;
};


const AddNotification = ({ openNotificationModal, NotificationModalHandler, selectedCourse, selectedClass }) => {
  const [maxScreen, setMaxScreen] = useState(false);
  const backendBaseUrl = config.backendLocalhost;
  const userInfo = useSelector(state => state.userInfo);
  const ResponseMessages = useSelector(state => state.responseMessage);

  const [formData, setFormData] = useState({
    status: [],
    Notificationfile: null,
    description: '',
  });

  const resetForm = () => {
    setFormData({
      status: [],
      Notificationfile: null,
      description: '',
    });
    NotificationModalHandler('', '')
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      Notificationfile: file,
    }));
  };
  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const addNotification = async () => {
    let loadingToast = "";
    try {
      if (formData.facultyFile === null && formData.description === '') {
        toast.error("ERROR! Upload File or Write some description");
        return;
      }
      const formDataToSend = new FormData();
      formDataToSend.append("status", formData.status);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("Notificationfile", formData.Notificationfile);
      formDataToSend.append("className", selectedClass);
      formDataToSend.append("course", selectedCourse);
      loadingToast = toast.loading('Loading...');

      const response = await axios.post(`${backendBaseUrl}/notification/send`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const ResponseMsg = MessageByName("Bulk message to a class", ResponseMessages);
      toast.success(`${ResponseMsg}: ${selectedClass}`);
      Apis.addAuditTrail(userInfo._id, "6634931d4fba113615476d1b", `- [ClassID:${selectedClass}, CourseID:${selectedCourse}]`);
      toast.dismiss(loadingToast);
      resetForm();
    } catch (error) {
      console.error('Error sending data to the backend:', error);
      toast.error('Error sending Notification:', error.message || 'Unknown error');
      toast.dismiss(loadingToast);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addNotification();
  };

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
  }
  return (
    <div
      className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
${openNotificationModal
          ? "scale-100 duration-300"
          : "scale-0 -z-10"
        }
${maxScreen
          ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
          : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
        }
`}
    >
      <header
        className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
          }`}
      >
        <div>
          <h1 className="text-[2vw] font-semibold">Send Notification to class</h1>
        </div>
        <div className="flex gap-x-[1vw]">
          <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
            <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
          </button>
          <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
            {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
              <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
          </button>
          {/* Provide the implementation for AddProjectHandler */}
          <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => NotificationModalHandler('', '')}>
            <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
          </button>
        </div>
      </header>
      <div className={`w-full flex flex-col justify-center items-center
    ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
    `}>
        <div className='w-[90%] h-[90%] bg-gray-100 rounded-[1vw] flex flex-col gap-y-[1vh] p-[1vw]'>

          <div className='flex flex-col justify-center items-center'>
            <div className='w-full flex items-end'>
              <p className='text-[1.2vw] text-black text-left'>Brief Description:</p>
            </div>
            <textarea
              className='w-full text-[1.2vw] p-[1vw] style-scrollbar border border-blue-300 focus:border-blue-900 outline-blue-900 duration-500'
              placeholder='Write the description here...'
              value={formData.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
            >
            </textarea>

          </div>
          <div className='w-full flex flex-col w-full justify-center items-center'>
            <input
              id="file_input"
              type="file"
              className="w-full block px-[1vw] py-[1vh] leading-[3vh] transition-all duration-300 ease-in-out border border-blue-300 rounded-md appearance-none focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              onChange={(e) => handleFileChange(e)}
            />
          </div>
          <div className='flex flex-row justify-center items-center py-[1vh] gap-x-[1vw]'>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              className='min-h-[5vh] px-[5vw] text-[1vw] font-semibold rounded-[1vw] text-black bg-green-400 hover:text-white hover:bg-green-700 duration-300'>
              Upload
            </button>
            <button
              onClick={() => NotificationModalHandler('', '')}
              className='min-h-[5vh] px-[5vw] text-[1vw] font-semibold rounded-[1vw] text-black bg-red-400 hover:text-white hover:bg-red-700 duration-300'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNotification